import React from "react"
import { graphql } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { linkResolver } from "../utils/linkResolver"
import Single from "../components/single/single"
import SingleContent from "../components/single/singleContent"
import Seo from "../components/seo"

const ResourceTemplate = ({ data }) => {
  const pageData = data.prismicResource.data

  return (
    <Single heading={pageData.heading}>
      <Seo
        title={pageData.meta_title || pageData.heading}
        description={pageData.meta_description}
      />
      <SingleContent
        body={pageData.body}
        button={{ link: pageData.button_link, title: pageData.button_title }}
      />
    </Single>
  )
}

export const query = graphql`
  query ResourceTemplate($id: String) {
    prismicResource(id: { eq: $id }) {
      _previewable
      data {
        heading
        body {
          html
        }
        button_link {
          link_type
          uid
          url
          target
        }
        button_title
        meta_title
        meta_description
      }
    }
  }
`

export default withPrismicPreview(ResourceTemplate, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME,
    linkResolver,
  },
])
